@use '@angular/material' as mat;
@use 'sass:color';

:root {
  //bouton jaune
  .btn-primaire {
    width: 100%;
    border-radius: 4px;
    height: 60px;
    transition:
      background-color 0.25s ease-out,
      color 0.25s ease-out;
    @include mat.button-overrides(
      (
        filled-container-color: var(--color-primary-500),
        filled-label-text-color: black,
        filled-ripple-color: var(--color-primary-800),
        filled-disabled-container-color: var(--color-primary-300),
        filled-disabled-label-text-color: grey,
        filled-focus-state-layer-opacity: 0,
      )
    );
    &:hover {
      @include mat.button-overrides(
        (
          filled-container-color: var(--color-primary-200),
          filled-label-text-color: color.adjust(black, $lightness: 30%),
        )
      );
    }
  }
  //bouton blanc
  .btn-secondaire {
    width: 100%;
    border-radius: 4px;
    height: 60px;
    border-bottom: 3px solid var(--color-grey-100);
    transition:
      background-color 0.25s ease-out,
      color 0.25s ease-out,
      box-shadow 0.3s ease-in-out;
    @include mat.button-overrides(
      (
        filled-container-color: white,
        filled-label-text-color: black,
        filled-ripple-color: var(--color-grey-200),
        filled-disabled-container-color: var(--color-grey-050),
        filled-disabled-label-text-color: var(--color-grey-200),
        filled-focus-state-layer-opacity: 0,
        //outlined-outline-width:30px
      )
    );
    &:hover {
      box-shadow: inset 0em -4.5em var(--color-grey-100);
    }
  }

  //bouton switch
  .slider {
    @include mat.slide-toggle-overrides(
      (
        //fond
        selected-track-color: var(--color-secondary-600),
        selected-focus-track-color: var(--color-secondary-600),
        selected-hover-track-color: var(--color-secondary-400),
        selected-pressed-track-color: var(--color-secondary-400),
        unselected-pressed-track-color: var(--color-secondary-400),
        unselected-track-color: var(--color-secondary-200),
        unselected-focus-track-color: var(--color-secondary-200),
        unselected-hover-track-color: var(--color-secondary-200),
        //contour
        track-outline-color: var(--color-secondary-600),
        disabled-unselected-track-outline-color: var(--color-secondary-600),
        //icon
        selected-icon-color: var(--color-secondary-600),
        unselected-icon-color: var(--color-secondary-050),
        //rond
        unselected-handle-color: var(--color-secondary-600),
        unselected-focus-handle-color: var(--color-secondary-600),
        unselected-hover-handle-color: var(--color-secondary-600),
        unselected-pressed-handle-color: var(--color-secondary-700),
        selected-pressed-handle-color: var(--color-secondary-100),
        selected-handle-color: var(--color-secondary-050),
        selected-focus-handle-color: var(--color-secondary-050),
        selected-hover-handle-color: var(--color-secondary-050)
      )
    );
  }
  .btnGroupe {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .icon_btn {
      max-width: 30px;
      max-height: 30px;
      margin-right: 10px;
      &.icon_primaire {
        background-color: var(--color-primary-400);
      }
      &.icon_danger {
        background-color: var(--color-danger-400);
        color: var(--color-danger-100);
      }
    }
  }

  div {
    @include mat.menu-overrides(
      (
        item-label-text-color: black,
        container-color: white,
      )
    );
  }

  .menu_btn {
    justify-content: flex-end;
    width: 30%;
    .btn-primaire {
      width: 100%;
      border-radius: 4px;
      height: 60px;
      background-color: var(--color-primary-500);
      color: black;
      transition:
        background-color 0.25s ease-out,
        color 0.25s ease-out;
      &:hover {
        background-color: var(--color-primary-200);
        color: color.adjust(black, $lightness: 30%);
      }
      .btn_menu_content {
        display: flex;
        align-items: center;
      }
    }
  }
}
