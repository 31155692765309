// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'styles/m3-theme.scss';
@use 'styles/base.scss';
@use 'styles/couleurs.scss';
@use 'styles/table.scss';
@use 'styles/boutons.scss';
@use 'styles/inputs.scss';
@use 'styles/modal.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.all-component-themes(m3-theme.$light-theme);

  @include mat.system-level-colors(m3-theme.$light-theme);
  @include mat.system-level-typography(m3-theme.$light-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($theme);
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
