@use '@angular/material' as mat;

.dialog_title {
  display: flex !important;
  justify-content: center;
  align-items: center;
  .dialog_header {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: '';
    width: 100%;
    height: 100%;
    .dailog_img {
      width: 100px;
      display: block;
      content: '';
      height: 100px;
      background-image: var(--imgModalDanger);
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
}
.dialog_content {
  display: flex;
  margin-bottom: 20px;
  text-align: center;
  flex-direction: column;
}
.dialog_action {
  display: flex;
  flex-direction: row;

  align-items: center;
  .btn_liste {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
:root {
  @include mat.dialog-overrides(
    (
      actions-alignment: center,
    )
  );
}
