.bs_table {
  thead tr th {
    div {
      justify-content: center;
    }
  }
  .progress_bar_container {
    max-width: 50px;
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    border-color: var(--color-secondary-200);
    text-align: center;
    font-size: 0.75rem;
    padding: 0;
    max-width: 50px;
    .mat-sort-header-container {
      .mat-sort-header-content {
        min-width: 0;
        div {
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          white-space: nowrap;
        }
      }
    }
  }
  .mdc-checkbox__ripple {
    background-color: var(--color-secondary-500) !important;
  }

  .mat-mdc-row:hover {
    background-color: var(--color-grey-050);
    cursor: pointer;
  }
}
