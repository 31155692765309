@use '@angular/material' as mat;
@use 'sass:color';
@use '@angular/cdk';
@include cdk.text-field-autofill();

//Pour le moment la surcharge sur les inputs a proprement parlé n'est pas prise en charge
.mdc-text-field--outlined .mdc-text-field__input {
  background-color: rgba(255, 255, 255, 0) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--color-secondary-600) !important;
}

:root {
  body {
    //checkbox
    @include mat.checkbox-overrides(
      (
        //label color
        disabled-label-color: var(--color-grey-400),
        label-text-color: black,
        //encoche
        selected-checkmark-color: white,
        //fond
        selected-icon-color: var(--color-secondary-500),
        selected-hover-icon-color: var(--color-secondary-600),
        selected-focus-icon-color: var(--color-secondary-500),
        unselected-icon-color: var(--color-grey-800),
        //ripple
        selected-focus-state-layer-color: var(--color-secondary-400),
        selected-hover-state-layer-color: var(--color-secondary-500),
        selected-pressed-state-layer-color: var(--color-secondary-600),
        unselected-focus-state-layer-color: var(--color-secondary-200),
        unselected-hover-state-layer-color: var(--color-secondary-400),
        unselected-pressed-state-layer-color: var(--color-secondary-400)
      )
    );

    //auto complete
    @include mat.autocomplete-overrides(
      (
        background-color: white,
      )
    );

    mat-option {
      background-color: white !important;
      transition: background-color 0.3s ease-out;

      &:hover {
        background-color: var(--color-secondary-050) !important;
      }
    }

    //datePicker
    @include mat.datepicker-overrides(
      (
        calendar-container-background-color: white,
        //calendrier date select
        calendar-date-selected-state-background-color: var(--color-secondary-600),
        calendar-date-today-outline-color: var(--color-secondary-600),
        calendar-date-in-range-state-background-color: var(--color-secondary-200),
        calendar-date-preview-state-outline-color: var(--color-secondary-600),
        calendar-date-hover-state-background-color: var(--color-secondary-050),
        //date label header
        calendar-body-label-text-color: var(--color-secondary-800),
        calendar-period-button-icon-color: var(--color-secondary-800),
        calendar-period-button-text-color: var(--color-secondary-800),
        calendar-navigation-button-icon-color: var(--color-secondary-800),
        calendar-header-text-color: var(--color-secondary-700),
      )
    );
    //formfield
    @include mat.form-field-overrides(
      (
        filled-input-text-placeholder-color: red,
        filled-caret-color: red,
        filled-focus-active-indicator-color: var(--color-secondary-600),
        filled-focus-label-text-color: var(--color-secondary-600),
        filled-container-color: white,
        filled-disabled-container-color: var(--color-grey-400),
        filled-label-text-color: var(--color-grey-800),
        filled-hover-label-text-color: var(--color-grey-700),
        filled-input-text-color: var(--color-grey-800),
        filled-error-hover-label-text-color: var(--color-danger-600),
        filled-error-focus-label-text-color: var(--color-danger-600),
        filled-error-label-text-color: var(--color-danger-600),
        filled-error-caret-color: var(--color-danger-600),
        filled-active-indicator-color: var(--color-grey-800),
        filled-error-active-indicator-color: var(--color-danger-600),
        filled-error-focus-active-indicator-color: var(--color-danger-600),
        filled-error-hover-active-indicator-color: var(--color-danger-600),
        outlined-focus-label-text-color: var(--color-secondary-600),
        outlined-label-text-color: var(--color-grey-800),
        outlined-hover-label-text-color: var(--color-grey-700),
        outlined-disabled-label-text-color: var(--color-grey-400),
        outlined-input-text-color: var(--color-grey-800),
        outlined-error-caret-color: var(--color-danger-600),
        outlined-error-focus-label-text-color: var(--color-secondary-600),
        outlined-error-label-text-color: var(--color-danger-600),
        outlined-error-hover-label-text-color: var(--color-danger-600),
        outlined-outline-color: var(--color-grey-800),
        outlined-disabled-outline-color: var(--color-grey-400),
        outlined-hover-outline-color: var(--color-grey-700),
        outlined-error-focus-outline-color: var(--color-danger-600),
        outlined-error-hover-outline-color: var(--color-danger-600),
        outlined-error-outline-color: var(--color-danger-600),
        state-layer-color: var(--color-secondary-050),
        error-text-color: var(--color-danger-600),
        error-focus-trailing-icon-color: var(--color-danger-600),
        error-hover-trailing-icon-color: var(--color-danger-600),
        error-trailing-icon-color: var(--color-danger-600),
      )
    );
    mat-form-field {
      @include mat.icon-overrides(
        (
          color: var(--color-grey-800),
        )
      );
    }
  }
}
