:root {
  --color-primary-800: #c9902e;
  --color-primary-600: #e0a033;
  --color-primary-500: #f2b241;
  --color-primary-400: #ffdc85;
  --color-primary-300: #ffe5a4;
  --color-primary-200: #ffedc2;
  --color-primary-100: #fff6e1;

  --color-secondary-800: #202e5b;
  --color-secondary-700: #304488;
  --color-secondary-600: #4867cc;
  --color-secondary-500: #5072e3;
  --color-secondary-400: #738ee9;
  --color-secondary-200: #b9c7f4;
  --color-secondary-100: #dce3f9;
  --color-secondary-050: #f3f5fd;
  --color-secondary-100-opct: rgba(220, 227, 249, 0.4);

  --color-grey-800: #33363e;
  --color-grey-700: #4d505e;
  --color-grey-600: #666b7d;
  --color-grey-500: #80869c;
  --color-grey-400: #999eb0;
  --color-grey-300: #b3b6c4;
  --color-grey-200: #cccfd7;
  --color-grey-100: #e6e7eb;
  --color-grey-050: #f5f5f7;

  --color-success-700: #03625c;
  --color-success-600: #04837b;
  --color-success-500: #05a49a;
  --color-success-400: #37b6ae;
  --color-success-300: #69c8c2;
  --color-success-100: #cdedeb;
  --color-success-100-opct: #e9f5f5;

  --color-info-600: #4867cc;
  --color-info-500: #5072e3;
  --color-info-400: #738ee9;
  --color-info-050: #f3f5fd;

  --color-danger-600: #ad314e;
  --color-danger-500: #d83d62;
  --color-danger-400: #e06481;
  --color-danger-100: #f7d8e0;

  --color-black: #00061b;
  --color-white: #ffffff;
  --color-bgrd: #eff2f9;
  --color-border: #80869c;
  --color-border-disabled: #b3b6c4;
  --color-sidebar: #26366d;
  --color-tooltip: #10172d;
  --color-tertiary: #cfd8dc;
  --color-shadow: #26376d1f;

  // plan colors
  --color-plan-ldr-mask: rgb(179, 182, 196, 0.5);
  --color-seat-disabled: #e6e7eb;
  --color-scale-rect-bgrd: rgb(251, 193, 48, 0.1);
  --color-scale-rect-border: #fbc130;

  --background-loader: url('../assets/img/T&LB_logo_no_ball.svg');
  --background-logoBC: url('../assets/img/T&LB_logo_colored.svg');
  --background-logo: url('../assets/img/T&LBackstage_logo_colored.svg');
  --background-logo-tlcolor: url('../assets/img/tick&live_logo_colored.svg');
  --imgModalDanger: url('../assets/img/type=danger.png');
}
