@use 'sass:color';
.flex-columns {
  flex-direction: column;
}

.flex {
  display: flex;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.gap10 {
  gap: 10px;
}

.body-sub-container {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 90%;
}
h4 {
  font-size: 1rem;
  color: var(--color-secondary-800);
}
.body-container {
  flex-direction: column;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  .content_header {
    z-index: 1000;
    top: 0;
    background-color: white;
    min-height: 80px;
    height: 80px;
    width: 100%;
    box-shadow: 0 4px 3px var(--color-shadow);
    display: block;
    position: relative;
    margin-bottom: 20px;
  }
  .content_body {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

#rapprochements_grid {
  .dx-datagrid {
    .dx-pager .dx-pages .dx-selection,
    .dx-pager .dx-page-sizes .dx-selection {
      background-color: var(--color-secondary-700);
    }

    .dx-datagrid-headers
      .dx-datagrid-table
      .dx-row
      > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(
        .dx-datagrid-group-space
      ) {
      &:hover {
        background-color: var(--color-grey-100);
      }
    }
  }
}

.progress_bar_container {
  max-width: 60px;
  min-width: 60px;
}

.progress_bar {
  width: 100%;
  justify-content: center;
  //max-width: fit-content;
  display: flex;

  .progress_bar_montant {
    font-size: smaller;
    text-align: right;
    min-width: 40%;
    //margin-right: 5px;
  }

  .progress_bar_content {
    min-width: 30%;
    //min-width: 20px;
    text-align: center;
    font-weight: bold;
    padding-left: 4px;
    padding-right: 4px;
  }

  &:not(.barre_ok) {
    color: var(--color-secondary-500);

    .mdc-linear-progress__bar-inner {
      border-color: var(--color-secondary-500) !important;
    }
  }

  &.barre_ok {
    color: var(--color-success-500);

    .mdc-linear-progress__bar-inner {
      border-color: var(--color-success-500) !important;
    }
  }
}

.mat-mdc-tab-labels {
  .mdc-tab--active,
  .mdc-tab-indicator--active {
    background-color: var(--color-primary-400);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.listeCB {
  .nomTit {
    font-size: 1rem;
  }

  .iban {
    font-size: x-small !important;
    color: var(--color-secondary-400) !important;
  }
}

.panel_tabs {
  .mat-mdc-tab-body-wrapper {
    height: 100% !important;
  }
}

.mat-mdc-tooltip-surface {
  //couleur de fond pour les tooltips
  background-color: var(--color-secondary-600) !important;
}

.mat-mdc-dialog-surface {
  background-color: white !important;
}

.version_container {
  .mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0;
    background-color: white;
  }

  .mat-expansion-panel-spacing {
    .test {
      background-color: var(--color-secondary-600);
    }

    margin: 0;
  }

  .mat-accordion .mat-expansion-panel:first-of-type {
    border-radius: 0;
  }

  .mat-accordion .mat-expansion-panel:last-of-type {
    border-radius: 0;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    flex-grow: 0;
  }
}

.capsule {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;

  .caps {
    display: flex;
    border-radius: 20px;
    min-height: 20px;
    align-items: center;
    justify-content: center;
    max-width: 80%;

    mat-icon {
      height: 15px;
      width: 15px;
      font-size: 15px;
      margin-right: 5px;
      margin-left: 5px;
    }

    p,
    div {
      margin: 0;
      height: 20px;
      font-size: 10px;
      padding-right: 10px;
      padding-left: 10px;
    }

    div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .capsOk {
    color: var(--color-sucess-600);
    background-color: var(--color-success-100);
  }

  .capsKo {
    color: var(--color-danger-100);
    background-color: var(--color-danger-600);
  }

  .capsWarning {
    color: var(--color-danger-600);
    background-color: var(--color-danger-100);
  }

  .capsInfo {
    color: var(--color-secondary-100);
    background-color: var(--color-secondary-600);
  }

  .capsWait {
    color: var(--color-grey-700);
    background-color: var(--color-grey-100);
  }
}
